@font-face {
  font-family: 'icons';
  src:
    url('fonts/icons.ttf?44zweu') format('truetype'),
    url('fonts/icons.woff?44zweu') format('woff'),
    url('fonts/icons.svg?44zweu#icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-active-order-finish:before {
  content: "\f101";
}
.icon-active-order-run:before {
  content: "\f102";
}
.icon-active-order-setup:before {
  content: "\f103";
}
.icon-active-order:before {
  content: "\f104";
}
.icon-arrow-down-open:before {
  content: "\f105";
}
.icon-arrow-left:before {
  content: "\f106";
}
.icon-arrow-right-back:before {
  content: "\f107";
}
.icon-arrow-right-filled-open:before {
  content: "\f108";
}
.icon-arrow-right-open:before {
  content: "\f109";
}
.icon-arrow-select:before {
  content: "\f10a";
}
.icon-arrow-up-close:before {
  content: "\f10b";
}
.icon-arrows-left-long:before {
  content: "\f10c";
}
.icon-arrows-right-filled-close:before {
  content: "\f10d";
}
.icon-arrows-right-long:before {
  content: "\f10e";
}
.icon-arrows-right:before {
  content: "\f10f";
}
.icon-arrows-select:before {
  content: "\f110";
}
.icon-automated:before {
  content: "\f111";
}
.icon-barcode:before {
  content: "\f112";
}
.icon-checked-solid:before {
  content: "\f113";
}
.icon-close:before {
  content: "\f114";
}
.icon-collapse-all:before {
  content: "\f115";
}
.icon-container:before {
  content: "\f116";
}
.icon-dashboard-solid:before {
  content: "\f117";
}
.icon-dashboard:before {
  content: "\f118";
}
.icon-delete:before {
  content: "\f119";
}
.icon-document-outline:before {
  content: "\f11a";
}
.icon-documents:before {
  content: "\f11b";
}
.icon-drop:before {
  content: "\f11c";
}
.icon-edit:before {
  content: "\f11d";
}
.icon-error-outline:before {
  content: "\f11e";
}
.icon-error-solid:before {
  content: "\f11f";
}
.icon-expand-all:before {
  content: "\f120";
}
.icon-feedback-outline:before {
  content: "\f121";
}
.icon-feedback-solid:before {
  content: "\f122";
}
.icon-flag-outline:before {
  content: "\f123";
}
.icon-flag-solid:before {
  content: "\f124";
}
.icon-help:before {
  content: "\f125";
}
.icon-home:before {
  content: "\f126";
}
.icon-info-outline:before {
  content: "\f127";
}
.icon-info-solid:before {
  content: "\f128";
}
.icon-order-list:before {
  content: "\f129";
}
.icon-order-status-1-4:before {
  content: "\f12a";
}
.icon-order-status-2-4:before {
  content: "\f12b";
}
.icon-order-status-3-4:before {
  content: "\f12c";
}
.icon-order-status-4-4:before {
  content: "\f12d";
}
.icon-order-status-not-relevant:before {
  content: "\f12e";
}
.icon-language:before {
  content: "\f12f";
}
.icon-machine:before {
  content: "\f130";
}
.icon-material-1-4:before {
  content: "\f131";
}
.icon-material-2-4:before {
  content: "\f132";
}
.icon-material-3-4:before {
  content: "\f133";
}
.icon-material-4-4:before {
  content: "\f134";
}
.icon-material-not-relevant:before {
  content: "\f135";
}
.icon-minus:before {
  content: "\f136";
}
.icon-mounting:before {
  content: "\f137";
}
.icon-nav-active-job:before {
  content: "\f138";
}
.icon-nav-dashboard:before {
  content: "\f139";
}
.icon-nav-documents:before {
  content: "\f13a";
}
.icon-nav-help:before {
  content: "\f13b";
}
.icon-nav-job-list:before {
  content: "\f13c";
}
.icon-nav-mounting:before {
  content: "\f13d";
}
.icon-nav-open-tasks:before {
  content: "\f13e";
}
.icon-nav-qa-log:before {
  content: "\f13f";
}
.icon-nav-settings:before {
  content: "\f140";
}
.icon-nav-shift-report:before {
  content: "\f141";
}
.icon-nav-shutdown:before {
  content: "\f142";
}
.icon-open-tasks:before {
  content: "\f143";
}
.icon-pallet-1-4:before {
  content: "\f144";
}
.icon-pallet-2-4:before {
  content: "\f145";
}
.icon-pallet-3-4:before {
  content: "\f146";
}
.icon-pallet-4-4:before {
  content: "\f147";
}
.icon-pallet-dummy:before {
  content: "\f148";
}
.icon-pallet-scanned:before {
  content: "\f149";
}
.icon-pallet:before {
  content: "\f14a";
}
.icon-pdf-outline:before {
  content: "\f14b";
}
.icon-plus:before {
  content: "\f14c";
}
.icon-power-off:before {
  content: "\f14d";
}
.icon-print-outline:before {
  content: "\f14e";
}
.icon-print-solid:before {
  content: "\f14f";
}
.icon-qa-log:before {
  content: "\f150";
}
.icon-qarning-outline:before {
  content: "\f151";
}
.icon-quality-assurance:before {
  content: "\f152";
}
.icon-reset:before {
  content: "\f153";
}
.icon-running-no-pallet:before {
  content: "\f154";
}
.icon-search:before {
  content: "\f155";
}
.icon-settings:before {
  content: "\f156";
}
.icon-shift-report:before {
  content: "\f157";
}
.icon-shutdown:before {
  content: "\f158";
}
.icon-sort-ascending:before {
  content: "\f159";
}
.icon-sort-default:before {
  content: "\f15a";
}
.icon-sort-descending:before {
  content: "\f15b";
}
.icon-standstill:before {
  content: "\f15c";
}
.icon-take-value:before {
  content: "\f15d";
}
.icon-trash-outline:before {
  content: "\f15e";
}
.icon-trash-small:before {
  content: "\f15f";
}
.icon-unmount-pallet:before {
  content: "\f160";
}
.icon-user:before {
  content: "\f161";
}
.icon-warehouse:before {
  content: "\f162";
}
.icon-warning-outline:before {
  content: "\f163";
}
.icon-warning-solid:before {
  content: "\f164";
}
.icon-wastepaper-outline:before {
  content: "\f165";
}
.icon-roll:before {
  content: "\f166";
}
.icon-roll-filled:before {
  content: "\f167";
}
.icon-clock:before {
  content: "\f168";
}
.icon-recycling:before {
  content: "\f169";
}
